import React from "react";
import { SessionProvider } from "next-auth/react";
import "@braze/beacon-fonts/Sailec.css";
import "@braze/beacon-core/dist/styles.css";

export default ({ Component, pageProps: { session, ...pageProps } }) => {
  return (
    // This makes next-auth happy https://next-auth.js.org/getting-started/example#add-to-all-pages
    <SessionProvider session={session}>
      <Component {...pageProps} />
    </SessionProvider>
  );
};
